import { render, staticRenderFns } from "./taxable_opt.vue?vue&type=template&id=081e7d9a&scoped=true&"
import script from "./taxable_opt.vue?vue&type=script&lang=js&"
export * from "./taxable_opt.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "081e7d9a",
  null
  
)

export default component.exports